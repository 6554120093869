import React from "react";
import Coworker from "./Coworker.js";
import CoworkerAvatar from "./CoworkerAvatar.js";

export default () => (
  <div>
    <section className="bg-white py-8">
      <div className="container mx-auto flex flex-wrap pt-4 pb-12">

        {/* LEDNING */}
        <Coworker
          name="Andreas Andersson"
          email="andreas.andersson@psksyd.com"
          phone=" "
          role="CEO"
          imageName={"andreasA"}
        />
        <Coworker
          name="Malin Ohlsson"
          email="malin.ohlsson@psksyd.com "
          phone="+46 (0)737-12 68 44"
          role="Deputy CEO"
          imageName={"MalinO"}
        />
        <Coworker
          name="Anneli Andersson"
          email="anneli.andersson@psksyd.com "
          phone=" "
          role="COO"
          imageName={"Anneli"}
        />        

        {/* KUNDANSVARIGA */}
        <Coworker
          name="Laura Lundberg"
          email="laura.lundberg@psksyd.com"
          phone=" "
          role="Client coordinator"
          imageName={"Laura"}
        />
        <Coworker
          name="Ulrica Andrén"
          email="ulrica.andren@psksyd.com"
          phone=" "
          role="Client coordinator"
          imageName={"ulrica"}
        />
        <Coworker
          name="Ann Johansson"
          email="ann.johansson@psksyd.com"
          pphone=" "
          role="Client coordinator"
          imageName={"ann"}
        />
        {/* ADMINISTRATION */}

        <Coworker
          name="Maja Engdahl"
          email="maja.engdahl@psksyd.com"
          phone=" "
          role="Business support"
          imageName={"Maja"}
        />
        <Coworker
          name="Ildi Turanji"
          email="ildi.turanji@psksyd.com"
          phone=" "
          role="Teamleader Business Center "
          imageName={"Ildi"}
        />        
        <Coworker
          name="Johanna Andersson"
          email="johanna.andersson@psksyd.com"
          phone=" "
          role="Business support"
          imageName={"johanna"}
        />
        <Coworker
          name="Ingela Svensson"
          email="ingela.svensson@psksyd.com"
          phone=" "
          role="Compliance coordinator"
          imageName={"ingela"}
        />
        <Coworker
          name="Tajana Habus"
          email="tajana.habus@psksyd.com"
          phone=" "
          role="Teamleader Compliance Center"
          imageName={"Tajana"}
        />
        <Coworker
          name="Pernilla Blomsterlund"
          email="pernilla.blomsterlund@psksyd.com"
          phone=" "
          role="Compliance coordinator"
          imageName={"Pernilla"}
        />        
        {/* CAD & VERKSAMHETSKONSULTER */}
        <Coworker
          name="Eldira Telalovic"
          email="eldira.telalovic@psksyd.com"
          phone=" "
          role="Compliance coordinator"
          imageName={"eldira"}
        />
        <Coworker
          name="Hrvoje Habus"
          email=" "
          phone=" "
          role="Technical design"
          imageName={"hrvoje"}
        />
        <CoworkerAvatar
          name="Rebecca Wingren"
          email="rebecca.wingren@psksyd.com"
          phone=" "
          role="Technical design"
          imageName={"femaleAvatar"}
        />
        <Coworker
          name="Simon Blomsterlund"
          email="simon.blomsterlund@psksyd.com"
          phone=" "
          role="Technical design"
          imageName={"simonB"}
        />
        <Coworker
          name="Simon Matthiessen"
          email="simon.matthiessen@psksyd.com"
          phone=" "
          role="Technical design"
          imageName={"simonM"}
        />

        {/* Systemutvecklare */}
        <Coworker
          name="Robin Ingmarsson"
          email=" "
          phone=" "
          role="Operations Manager"
          imageName={"Robin"}
        />            
        <Coworker
          name="Acke Küttner"
          email=" "
          phone=" "
          role="System developer"
          imageName={"Acke"}
        />
        <Coworker
          name="Fredrik Malmborg"
          email=" "
          phone=" "
          role="System developer"
          imageName={"fredrik"}
        />
        <Coworker
          name="Peter Björck"
          email=" "
          phone=" "
          role="System developer"
          imageName={"peter"}
        />        
        <Coworker
          name="Henrik Andersson"
          email=" "
          phone=" "
          role="System developer"
          imageName={"Henrik"}
        />        
      </div>
    </section>
  </div>
);